import { Component, computed, inject, input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AuthStore } from '@trueleap/auth';
@Component({
  selector: 'trueleap-plus-live-webiner',
  standalone: true,
  imports: [RouterModule],
  template: `
    <iframe
      header="Trueleap meeting call"
      allow="camera *;microphone *;display-capture *"
      class="flex-grow-1"
      #iframe
      [src]="sanitizer.bypassSecurityTrustResourceUrl(meetingLink())"
      style="border: none; width: 100%">
    </iframe>
  `,
  styles: [
    `
      :host {
        display: flex;
        justify-content: center;
        width: 100%;
      }
    `,
  ],
})
export class WebinerLiveComponent {
  meetingCode = input.required<string>();
  sanitizer = inject(DomSanitizer);
  profile = inject(AuthStore).profile;
  meetingLink = computed(() => {
    return `https://trueleap-teach-dev.app.100ms.live/meeting/${this.meetingCode()}?name=${this.username()}`;
  });
  username = computed(() => {
    return this.profile()?.firstname + ' ' + this.profile()?.lastname;
  });
}
