import { CommonModule } from '@angular/common';
import { Component, OnInit, inject, output } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MtxCheckboxGroup } from '@ng-matero/extensions/checkbox-group';
import { NgSelectModule } from '@ng-select/ng-select';
import { injectQuery } from '@tanstack/angular-query-experimental';
import { lastValueFrom } from 'rxjs';
import { WebinerService } from '../webiner.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

type Category = {
  id: number;
  name: string;
};
type Star = number;

@Component({
  selector: 'trueleap-plus-webiner-filter',
  standalone: true,
  imports: [
    CommonModule,
    NgSelectModule,
    MatCardModule,
    MatDividerModule,
    MatExpansionModule,
    ReactiveFormsModule,
    FormsModule,
    MatCheckboxModule,
    MatIconModule,
    MatButtonModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MtxCheckboxGroup,
    NgSelectModule,
  ],
  templateUrl: './webiner-filter.component.html',
  styleUrl: './webiner-filter.component.scss',
})
export class WebinerFilterComponent implements OnInit {
  fb = inject(FormBuilder);
  webinerService = inject(WebinerService);
  authorOpenState = false;
  ratingOpenState = false;
  categoryOpenState = false;
  filterForm: FormGroup;
  selectedAuthor?: (string | null)[] | null;
  selectedStar?: (number | null)[] | null;
  selectedCategory?: (number | null)[] | null;
  applyFilter = output<any>();
  matDialogRef = inject(MatDialogRef);
  matDialogData = inject(MAT_DIALOG_DATA);
  eventAuthorData = injectQuery(() => ({
    queryKey: ['event-author'],
    queryFn: () => lastValueFrom(this.webinerService.fetchEventAuthors()),
  }));
  eventTagsData = injectQuery(() => ({
    queryKey: ['event-tags'],
    queryFn: () => lastValueFrom(this.webinerService.fetchTags()),
  }));
  ngOnInit(): void {
    this.filterForm = this.fb.group({
      author:
        this.matDialogData?.author?.length > 0
          ? this.fb.control(this.matDialogData?.author)
          : this.fb.control([]),
      categories:
        this.matDialogData?.tags?.length > 0
          ? this.fb.control(this.matDialogData?.tags)
          : this.fb.control([]),
      startDate: this.matDialogData?.startDate
        ? this.fb.control(this.matDialogData?.startDate)
        : this.fb.control(null),
      endDate: this.matDialogData?.endDate
        ? this.fb.control(this.matDialogData?.endDate)
        : this.fb.control(null),
    });
  }
  filter() {
    this.matDialogRef.close({
      data: this.filterForm.getRawValue(),
    });
  }
}
