<div class="main-container">
  <div class="content-container">
    @if (webinerQueries.isPending()) {
      <div>loading...</div>
    } @else if (webinerQueries.isError()) {
    } @else {
      @if (webinerQueries.data(); as webiners) {
        @for (webiner of webiners; track webiner) {
          <trlp-webiner-card [WebinerDetails]="webiner"></trlp-webiner-card>
        }
      }
    }
  </div>
</div>
