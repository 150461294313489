<dashboard-content-container
  [title]="eventId() ? 'Update Event' : 'Create Event'">
  <ng-container content-action>
    <div class="form-footer">
      <button mat-raised-button class="secondary-button" (click)="cancel()">
        <mat-icon>cancel</mat-icon>
        <span>Cancel</span>
      </button>
      <button
        mat-raised-button
        class="primary-button"
        (click)="saveWebiner()"
        [disabled]="
          eventForm.invalid ||
          mutation.isPending() ||
          eventEditMutation.isPending()
        ">
        <mat-icon>save</mat-icon>
        <span
          >{{
            mutation.isPending() || eventEditMutation.isPending()
              ? 'Saving...'
              : 'Save'
          }}
        </span>
      </button>
    </div>
  </ng-container>
  <ng-container content-inner>
    <form [formGroup]="eventForm" class="form-container">
      <div class="form-input-container">
        <mat-form-field appearance="outline" class="">
          <mat-label>Title</mat-label>
          <input matInput placeholder="Event Title" formControlName="title" />
        </mat-form-field>
      </div>
      <div class="">
        <mat-form-field appearance="outline" class="">
          <mat-label>Price</mat-label>
          <input
            matInput
            placeholder="Event Price"
            type="number"
            min="0"
            formControlName="price" />
        </mat-form-field>
      </div>
      <div class="form-input-container">
        <mat-form-field appearance="outline" class="">
          <mat-label>Description</mat-label>
          <textarea
            matInput
            placeholder="Description"
            formControlName="description"></textarea>
        </mat-form-field>
      </div>
      <div class="">
        <mat-form-field floatLabel="always" appearance="outline" class="">
          <mat-label class="font-medium" for="language"
            >Select Language</mat-label
          >
          <mat-select formControlName="language">
            @for (item of languages; track item) {
              <mat-option [value]="item.id">{{ item.name }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>

      <div class="form-input-container">
        <div class="form-label">Event Tags</div>
        <div class="form-input-add">
          <ng-select formControlName="tags" [multiple]="true">
            <ng-option
              *ngFor="let tag of tagsData.data() as tags"
              [value]="tag.id"
              >{{ tag.name }}</ng-option
            >
          </ng-select>
          <mat-icon (click)="handleAddTag()">add_circle</mat-icon>
        </div>
      </div>
      <div class="form-label">
        <mat-label>Event Type</mat-label>
      </div>
      <mat-radio-group
        aria-label="Event Type"
        class="form-input-container"
        formControlName="type">
        <mat-radio-button value="PUBLIC">Independent</mat-radio-button>
        <mat-radio-button value="COURSE">Course</mat-radio-button>
      </mat-radio-group>
      <!-- {{ courses.data() | json }} -->
      @if (this.eventForm.controls['type'].value === 'COURSE') {
        <div class="form-input-container">
          <div class="form-label">
            Course
            {{ this.eventForm.controls['type'].value === 'COURSE' ? '*' : '' }}
          </div>
          <ng-select formControlName="courseId" [multiple]="false">
            <ng-option
              *ngFor="let course of coursesData.data() as courses"
              [value]="course.id"
              >{{ course.title }}</ng-option
            >
          </ng-select>
        </div>
        <!-- <mat-form-field>
      <mat-label>Select Course</mat-label>
      <mtx-select
        [items]="courses.data() || []"
        bindLabel="title"
        bindValue="id"
        [multiple]="false"
        formControlName="courseId"
        appendTo="app-select-overview">
      </mtx-select>
    </mat-form-field> -->
      }
      <div class="form-date-time">
        <div class="form-input-container">
          <mat-slide-toggle formControlName="isRecurringEvent"
            >Recurring Event?</mat-slide-toggle
          >
        </div>
        <div class="form-input-container">
          <div class="form-label">
            <mat-label
              >Recurring Days
              {{
                eventForm.get('isRecurringEvent')?.value === true ? '*' : ''
              }}</mat-label
            >
          </div>
          <mtx-checkbox-group
            [disabled]="eventForm.get('isRecurringEvent')?.value === false"
            [items]="daysOfWeek"
            bindLabel="name"
            bindValue="value"
            formControlName="recurringDays">
          </mtx-checkbox-group>
        </div>
        <div class="date-container">
          <div>
            <div class="form-label">
              <mat-label
                >Event
                {{
                  this.eventForm.get('isRecurringEvent')?.value === true
                    ? 'Start'
                    : ''
                }}
                Date</mat-label
              >
            </div>
            <mat-form-field appearance="outline" class="">
              <mat-label>Date</mat-label>
              <input
                matInput
                [matDatepicker]="startPicker"
                formControlName="startDate" />
              <mat-datepicker-toggle
                matIconSuffix
                [for]="startPicker"></mat-datepicker-toggle>
              <mat-datepicker #startPicker></mat-datepicker>
            </mat-form-field>
          </div>
          @if (this.eventForm.get('isRecurringEvent')?.value === true) {
            <div>
              <div class="form-label">
                <mat-label>Event End Date</mat-label>
              </div>
              <mat-form-field appearance="outline" class="">
                <mat-label>Date</mat-label>
                <input
                  matInput
                  [matDatepicker]="endPicker"
                  formControlName="endDate" />
                <mat-datepicker-toggle
                  matIconSuffix
                  [for]="endPicker"></mat-datepicker-toggle>
                <mat-datepicker #endPicker></mat-datepicker>
              </mat-form-field>
            </div>
          }
        </div>
        <div class="time-container">
          <div class="">
            <div class="form-label">
              <mat-label>Event Start Time</mat-label>
            </div>
            <mat-form-field appearance="outline" class="">
              <input
                matInput
                formControlName="startTime"
                placeholder="12hr format"
                aria-label="12hr format"
                [ngxTimepicker]="startTime"
                readonly />
              <ngx-material-timepicker #startTime></ngx-material-timepicker>
            </mat-form-field>
          </div>
          <div class="">
            <div class="form-label">
              <mat-label>Event End Time</mat-label>
            </div>
            <mat-form-field appearance="outline" class="">
              <input
                matInput
                formControlName="endTime"
                placeholder="12hr format"
                aria-label="12hr format"
                [ngxTimepicker]="endTime"
                readonly />
              <ngx-material-timepicker #endTime></ngx-material-timepicker>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="form-input-container upload-container">
        <div class="form-label">Upload Thumbnail *</div>
        <div class="thumbnail-container" *ngIf="!isCroppersOpen()">
          @if (isImageChosen() || eventForm.controls['thumbnail'].value) {
            <img
              [src]="eventForm.controls['thumbnail'].value"
              height="240"
              width="240" />
          }
          <div class="image-cropper">
            <button
              mat-raised-button
              type="button"
              (click)="thumbnailInput.click()"
              [disabled]="isThumbnailUploading()">
              <input
                #thumbnailInput
                type="file"
                accept="image/*"
                (change)="thumbnailChangeEvent($event)"
                style="display: none" />
              {{
                isThumbnailUploading()
                  ? 'Uploading...'
                  : isImageChosen() || eventForm.controls['thumbnail'].value
                    ? 'Change Thumbnail'
                    : 'Choose Image'
              }}
            </button>
          </div>
        </div>
        <div *ngIf="isCroppersOpen()" class="cropperContainer">
          <image-cropper
            [imageChangedEvent]="imageChangedEvent"
            [maintainAspectRatio]="true"
            format="png"
            (imageCropped)="imageCropped($event)"
            (imageLoaded)="imageLoaded()"
            (cropperReady)="cropperReady()"
            (loadImageFailed)="loadImageFailed()"></image-cropper>
        </div>
        <div class="thumbnail-button-container" *ngIf="isCroppersOpen()">
          <button mat-raised-button color="warn" (click)="cancelCropped()">
            <mat-icon>cancel</mat-icon>
            <span>Cancel</span>
          </button>
          <button mat-raised-button color="primary" (click)="uploadThumbnail()">
            <mat-icon>save</mat-icon>
            <span>Save </span>
          </button>
        </div>
      </div>
      <div class="form-input-container">
        <mat-slide-toggle formControlName="isPublished"
          >Publish?</mat-slide-toggle
        >
      </div>
    </form>
  </ng-container>
</dashboard-content-container>
