/* eslint-disable @typescript-eslint/no-empty-function */
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  NgZone,
  SecurityContext,
  computed,
  effect,
  inject,
  signal,
} from '@angular/core';
import { Storage, getDownloadURL } from '@angular/fire/storage';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabChangeEvent, MatTabsModule } from '@angular/material/tabs';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { MtxCheckboxGroup } from '@ng-matero/extensions/checkbox-group';
import { MtxDialog, MtxDialogModule } from '@ng-matero/extensions/dialog';
import { MtxSelect } from '@ng-matero/extensions/select';
import { NgSelectModule } from '@ng-select/ng-select';
import {
  injectMutation,
  injectQuery,
} from '@tanstack/angular-query-experimental';
import { EditorModule } from '@tinymce/tinymce-angular';
import { FileuploadService } from '@trueleap-plus/shared-service';
import {
  ContentAction,
  ContentContainer,
  ContentInner,
  CreateTagComponent,
  PageHeaderComponent,
  Tag,
  WebinerPayload,
} from '@trueleap/ui-kit';
import { format } from 'date-fns';
import { ImageCroppedEvent, ImageCropperModule } from 'ngx-image-cropper';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { QuillEditorComponent } from 'ngx-quill';
import { Frequency, RRule, rrulestr } from 'rrule';
import { Subscription, lastValueFrom } from 'rxjs';
import { WebinerService } from '../webiner.service';
const CONTENT_TYPES = ['FILE', 'VIDEO', 'CONTENT'];
const dayMap: { [key: string]: any } = {
  MO: RRule.MO,
  TU: RRule.TU,
  WE: RRule.WE,
  TH: RRule.TH,
  FR: RRule.FR,
  SA: RRule.SA,
  SU: RRule.SU,
};

@Component({
  selector: 'trueleap-plus-create-webiner',
  standalone: true,
  imports: [
    ContentInner,
    ContentContainer,
    ContentAction,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    EditorModule,
    MatFormFieldModule,
    MatOptionModule,
    MatInputModule,
    MatSelectModule,
    PageHeaderComponent,
    MatSlideToggleModule,
    ImageCropperModule,
    MatIconModule,
    MatButtonModule,
    MatTabsModule,
    RouterModule,
    MatButtonModule,
    MatProgressBarModule,
    MatAutocompleteModule,
    MatChipsModule,
    QuillEditorComponent,
    NgSelectModule,
    EditorModule,
    NgxMaterialTimepickerModule,
    MatInputModule,
    MatDatepickerModule,
    MtxCheckboxGroup,
    MatRadioModule,
    MtxSelect,
    MtxDialogModule,
  ],
  templateUrl: './create-webiner.component.html',
  styleUrl: './create-webiner.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateWebinerComponent {
  fileuploadService = inject(FileuploadService);
  router = inject(Router);
  route = inject(ActivatedRoute);
  webinerService = inject(WebinerService);
  storage: Storage = inject(Storage);
  http = inject(HttpClient);
  mtxDialog = inject(MtxDialog);
  recurrenceSubs: Subscription;
  eventTypeSubs: Subscription;
  thumbnailSubs: Subscription;
  thumbnailfileName = signal<string>('');
  thumbnailFile: File;
  thumbnailUploadProgress = signal<number>(0);
  isThumbnailUploading = signal<boolean>(false);
  thumbnailfileLink = signal<string>('');
  eventId = signal<string | undefined>(undefined);
  loading = false;
  tagsData = injectQuery(() => ({
    queryKey: ['event-tags'],
    queryFn: () => lastValueFrom(this.webinerService.fetchTags()),
    refetchOnWindowFocus: false,
  }));
  coursesData = injectQuery(() => ({
    queryKey: ['courses'],
    queryFn: () => lastValueFrom(this.webinerService.fetchCourses()),
    refetchOnWindowFocus: false,
  }));
  mutation = injectMutation(client => ({
    mutationKey: ['events'],
    mutationFn: (data: WebinerPayload) =>
      lastValueFrom(this.webinerService.addWebiner(data)),
    onSuccess: async () => {
      this.router.navigate(['./../', 'dashboard', 'events', 'list']);
      return await client.invalidateQueries({
        queryKey: ['events'],
      });
    },
  }));
  eventEditMutation = injectMutation(client => ({
    mutationKey: ['webiner-details', this.eventId()],
    mutationFn: (data: WebinerPayload) =>
      lastValueFrom(
        this.webinerService.updateEvent(this.eventId() as string, data)
      ),
    onSuccess: async () => {
      this.router.navigate(['./../', 'dashboard', 'events', this.eventId()]);
      return await client.invalidateQueries({
        queryKey: ['webiner-details', this.eventId()],
      });
    },
  }));
  addTagMutation = injectMutation(client => ({
    mutationKey: ['event-tags'],
    mutationFn: (data: string) =>
      lastValueFrom(this.webinerService.addTag(data)),
    onSuccess: async () => {
      return await client.invalidateQueries({
        queryKey: ['event-tags'],
      });
    },
  }));
  eventForm: FormGroup;
  organizations: any = [];
  languages = [
    {
      id: 'EN',
      name: 'English',
    },
    {
      id: 'AS',
      name: 'Assamese',
    },
    {
      id: 'HI',
      name: 'Hindi',
    },
  ];
  daysOfWeek = [
    {
      name: 'Monday',
      value: 'mo',
    },
    {
      name: 'Tuesday',
      value: 'tu',
    },
    {
      name: 'Wednesday',
      value: 'we',
    },
    {
      name: 'Thursday',
      value: 'th',
    },
    {
      name: 'Friday',
      value: 'fr',
    },
    {
      name: 'Saturday',
      value: 'sa',
    },
    {
      name: 'Sunday',
      value: 'su',
    },
  ];
  config = {
    height: 200,
    menubar: false,
    plugins: [
      'advlist autolink lists link image charmap print preview anchor',
      'searchreplace visualblocks code fullscreen',
      'insertdatetime media table paste code help wordcount',
    ],
    toolbar:
      'undo redo | formatselect | bold italic backcolor | \
      alignleft aligncenter alignright alignjustify | \
      bullist numlist outdent indent | removeformat | help',
  };
  imageChangedEvent: any = '';
  croppedImage: any = '';
  imagePath: any = '';
  isImageChosen = signal<boolean>(false);
  isCroppersOpen = signal<boolean>(false);
  sanitizer = inject(DomSanitizer);
  ngZone = inject(NgZone);
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.blob;
  }
  getImagePath() {
    return this.sanitizer.sanitize(
      SecurityContext.RESOURCE_URL,
      this.sanitizer.bypassSecurityTrustResourceUrl(this.croppedImage)
    );
  }
  constructor() {
    this.route.params.subscribe(params => {
      this.eventId.set(params['id']); // Access the 'id' parameter from the URL
    });
    this.initForm();
    effect(() => {
      if (this.webinerDetailsData.isSuccess()) {
        this.updateFormWithData(this.webinerDetailsData.data());
      }
    });
  }
  eventIdValue = computed(() => this.eventId());
  webinerDetailsData = injectQuery(() => ({
    queryKey: ['webiner-details', this.eventIdValue()],
    queryFn: () => {
      const id = this.eventIdValue();
      return id
        ? lastValueFrom(this.webinerService.fetchEventByID(id))
        : Promise.reject('No event ID');
    },
    refetchOnWindowFocus: false,
    enabled: this.eventIdValue() !== undefined, // Conditionally enable the query
  }));
  private initForm() {
    this.eventForm = new UntypedFormGroup({
      title: new UntypedFormControl('', [
        Validators.required,
        this.noWhitespaceValidator,
      ]),
      price: new UntypedFormControl(0, Validators.required),
      description: new UntypedFormControl('', [
        Validators.required,
        this.noWhitespaceValidator,
      ]),
      thumbnail: new UntypedFormControl('', Validators.required),
      language: new UntypedFormControl('EN', Validators.required),
      courseId: new UntypedFormControl(null),
      tags: new UntypedFormControl([]),
      isPublic: new UntypedFormControl(false),
      isPublished: new UntypedFormControl(false),
      type: new UntypedFormControl('PUBLIC'),
      isRecurringEvent: new UntypedFormControl(false),
      recurringDays: new UntypedFormControl({ value: [], disabled: true }),
      startDate: new UntypedFormControl('', Validators.required),
      endDate: new UntypedFormControl(null),
      startTime: new UntypedFormControl('', Validators.required),
      endTime: new UntypedFormControl('', Validators.required),
    });
    const EventRecurrence = this.eventForm.controls['isRecurringEvent'];
    const EventType = this.eventForm.controls['type'];
    const EventThumbnail = this.eventForm.controls['thumbnail'];
    this.recurrenceSubs = EventRecurrence.valueChanges.subscribe(value => {
      if (value) {
        this.eventForm.addControl(
          'endDate',
          new FormControl(null, Validators.required)
        );
        this.eventForm.controls['recurringDays'].addValidators([
          Validators.required,
        ]);
        this.eventForm.controls['endDate'].updateValueAndValidity();
        this.eventForm.controls['recurringDays'].updateValueAndValidity();
      } else {
        this.eventForm.removeControl('endDate');
        this.eventForm.controls['recurringDays'].setValue([]);
        this.eventForm.controls['recurringDays'].setValidators(null);
        this.eventForm.controls['recurringDays'].updateValueAndValidity();
      }
    });
    this.eventTypeSubs = EventType.valueChanges.subscribe(value => {
      if (value === 'COURSE') {
        this.eventForm.controls['courseId'].addValidators([
          Validators.required,
        ]);
        this.eventForm.controls['courseId'].updateValueAndValidity();
      } else {
        this.eventForm.controls['courseId'].setValue(null);
        this.eventForm.controls['courseId'].setValidators(null);
        this.eventForm.controls['courseId'].updateValueAndValidity();
      }
    });
  }
  getFrequency(freq: number): string {
    switch (freq) {
      case 0:
        return 'Yearly';
      case 1:
        return 'Monthly';
      case 2:
        return 'Weekly';
      case 3:
        return 'Daily';
      case 4:
        return 'Hourly';
      case 5:
        return 'Minutely';
      case 6:
        return 'Secondly';
      default:
        return 'Unknown';
    }
  }

  extractDaysFromRrule(rruleString: string): string[] {
    // Extract the RRULE part from the string
    const rrulePart = rruleString.split('RRULE:')[1];
    if (!rrulePart) {
      return [];
    }

    // Split the RRULE string by semicolons
    const components = rrulePart.split(';');
    const bydayComponent = components.find(component =>
      component.startsWith('BYDAY')
    );

    let days: string[] = [];
    if (bydayComponent) {
      days = bydayComponent.split('=')[1].split(',');
    }

    // Map of RRULE days to full names

    return days.map(day => day.toLowerCase());
  }
  private updateFormWithData(data: any) {
    const rruleObj = rrulestr(data.rrule);
    const startDate = rruleObj.origOptions.dtstart
      ? new Date(rruleObj.origOptions.dtstart)
      : null;
    const endDate = rruleObj.origOptions.until
      ? new Date(rruleObj.origOptions.until)
      : null;
    const frequency = rruleObj.origOptions.freq
      ? this.getFrequency(rruleObj.origOptions.freq)
      : null;
    const daysOfWeek = rruleObj.origOptions.byweekday
      ? this.extractDaysFromRrule(data.rrule)
      : [];
    console.log(data);
    this.eventForm.patchValue({
      title: data.title,
      price: data.price,
      description: data.description,
      thumbnail: data.thumbnail,
      language: data.language,
      courseId: data.courseId,
      tags: data.tagToEvents.map((t: any) => t.tag.id),
      isPublic: data.isPublic,
      isPublished: data.isPublished,
      type: data.type,
      isRecurringEvent: frequency && endDate ? true : false,
      recurringDays: daysOfWeek,
      startDate: startDate,
      endDate: endDate,
      startTime: data.startTime,
      endTime: data.endTime,
    });
  }
  ngOnDestroy() {
    this.recurrenceSubs.unsubscribe();
  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }
  thumbnailChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.isCroppersOpen.set(true);
  }
  noWhitespaceValidator(control: any) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }
  uploadThumbnail() {
    this.isThumbnailUploading.set(true);
    this.isImageChosen.set(false);
    if (this.eventForm.get('thumbnail')?.value) {
      this.eventForm.get('thumbnail')?.setValue('');
    }
    this.imagePath = this.getImagePath();
    this.isCroppersOpen.set(false);
    const uploadTask = this.fileuploadService.uploadFile(
      new File([this.croppedImage], this.imageChangedEvent.target.files[0].name)
    );
    uploadTask.on(
      'state_changed',
      snapshot => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        this.ngZone.run(() => {
          this.thumbnailUploadProgress.set(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
        });
      },
      error => {
        this.ngZone.run(() => {
          this.isThumbnailUploading.set(false);
        });
        // Handle unsuccessful uploads
      },
      () => {
        // this.isThumbnailUploading.set(false);
        this.fileuploadService
          .uploadFileToDB({
            path: uploadTask.snapshot.ref.fullPath,
            file: new File(
              [this.croppedImage],
              this.imageChangedEvent.target.files[0].name
            ),
            type: 'IMAGE',
          })
          .subscribe((res: any) => {
            console.log('res', res);
          });
        getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
          this.ngZone.run(() => {
            this.thumbnailfileLink.set(downloadURL);
            this.eventForm.controls['thumbnail'].setValue(downloadURL);
            this.isThumbnailUploading.set(false);
            this.isImageChosen.set(true);
          });
        });
      }
    );
  }
  cancelCropped() {
    this.ngZone.run(() => {
      this.isImageChosen.set(false);
      this.isCroppersOpen.set(false);
    });
  }
  setTabChange(event: MatTabChangeEvent) {
    this.eventForm.get('type')?.setValue(CONTENT_TYPES[event.index]);
  }
  formatTime(time: string): string {
    // Using date-fns to format the time
    const formattedTime = format(
      new Date().setHours(
        Number(time.split(':')[0]),
        Number(time.split(':')[1].split(' ')[0]),
        0
      ),
      'h:mm aa'
    );
    return formattedTime;
  }
  saveWebiner() {
    if (this.eventForm.valid) {
      const payload = this.eventForm.getRawValue();
      const rule = new RRule({
        dtstart: payload.startDate,
        freq: Frequency.WEEKLY,
        byweekday: payload.recurringDays.map(
          (day: string) => dayMap[day.toUpperCase()]
        ),
        interval: 1,
        until: payload.endDate,
      });
      payload['rrule'] = rule.toString();
      if (this.eventId()) {
        this.eventEditMutation.mutate(payload);
      } else {
        this.mutation.mutate(payload);
      }
    }
  }
  cancel() {
    this.router.navigate(['./../', 'dashboard', 'events', 'list']);
  }
  displayFn(options: Tag[]): (id: string) => string {
    return (id: string) => {
      const correspondingOption = Array.isArray(options)
        ? options.find(option => option.id === id)
        : null;
      return correspondingOption ? correspondingOption.name : '';
    };
  }
  handleAddTag() {
    const dialogRef = this.mtxDialog.originalOpen(CreateTagComponent, {
      width: '550px',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (!result.isCancel) {
        console.log(result.tag);
        this.addTagMutation.mutate(result.tag);
      }
    });
  }
}
