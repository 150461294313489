import { Component, computed, inject, signal } from '@angular/core';
import {
  injectQuery,
  injectQueryClient,
} from '@tanstack/angular-query-experimental';
import { ActivatedRoute, Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { WebinerService } from './webiner.service';
import { PaymentComponent } from '@trueleap/payment';
import {
  ContentAction,
  ContentContainer,
  ContentInner,
} from '@trueleap/ui-kit';
import { AuthStore } from '@trueleap/auth';
import { rrulestr, RRule } from 'rrule';
import { format, isAfter, isBefore } from 'date-fns';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MtxDialog } from '@ng-matero/extensions/dialog';
import { WebinerLiveComponent } from './webiner-live.component';
@Component({
  selector: 'trueleap-plus-public-webiner-details',
  standalone: true,
  imports: [
    PaymentComponent,
    ContentInner,
    ContentContainer,
    ContentAction,
    MatIconModule,
    MatChipsModule,
    MatTabsModule,
    MatButtonModule,
    WebinerLiveComponent,
  ],
  template: `
    <div class="main-container">
      @if (checkoutSession()) {
        <trlp-payment
          [stripeKey]="stripeKey"
          [amount]="amount"
          [currency]="currency"
          (completePayment)="completedPayment($event)"
          [returnUrl]="returnUrl()"></trlp-payment>
      } @else if (webinerDetailsData.isPending()) {
        <dashboard-content-container header="Loading...">
          <ng-container content-inner>
            <div>loading...</div>
          </ng-container>
        </dashboard-content-container>
      } @else if (webinerDetailsData.isError()) {
      } @else {
        @if (webinerDetailsData.data(); as webinerDetails) {
          <dashboard-content-container header="{{ webinerDetails.title }}">
            <ng-container content-inner>
              <div class="inner-container">
                @if (isLiveWebiner()) {
                  <div class="event-meeting-back">
                    <button
                      mat-raised-button
                      color="primary"
                      (click)="joinLiveWebiner()">
                      Back To Details
                    </button>
                  </div>
                }
                <div class="event-container">
                  @if (isLiveWebiner()) {
                    <trueleap-plus-live-webiner
                      [meetingCode]="
                        getMeetingCode()
                      "></trueleap-plus-live-webiner>
                  } @else {
                    <div class="event-description">
                      <div class="event-details-container">
                        <div class="event-tabs-container">
                          <mat-tab-group
                            mat-stretch-tabs="false"
                            mat-align-tabs="start">
                            <mat-tab label="Host">
                              <div class="tabs-content">
                                <div class="tab-header">
                                  Host:
                                  {{ webinerDetails.authorInfo.firstname }}
                                  {{ webinerDetails.authorInfo.lastname }}
                                </div>
                                <div class="author-info">
                                  is a pioneering American author renowned for
                                  her historical romance novels. Often credited
                                  with revolutionizing the genre, Woodiwiss's
                                  works introduced intricate plots, strong
                                  heroines, and passionate romances set against
                                  vivid historical backdrops. Her debut novel,
                                  "The Flame and the Flower" (1972), achieved
                                  remarkable success, heralding a new era in
                                  romance literature. With a knack for weaving
                                  compelling narratives filled with intrigue,
                                  adventure, and timeless love, Woodiwiss
                                  captivated readers worldwide. Throughout her
                                  prolific career, she continued to produce
                                  beloved novels such as "Shanna" and "A Rose in
                                  Winter," leaving an indelible mark on the
                                  romance genre.
                                </div>
                              </div>
                            </mat-tab>
                            <mat-tab label="Overview">
                              <div class="tabs-content">
                                <div class="tab-header">Event Description:</div>
                                <div
                                  class="description-content"
                                  [innerHTML]="
                                    webinerDetails.description
                                  "></div>
                              </div>
                            </mat-tab>
                          </mat-tab-group>
                        </div>
                      </div>
                      @if (!isLiveWebiner()) {
                        <div class="event-meeting">
                          <button
                            mat-raised-button
                            color="primary"
                            (click)="joinLiveWebiner()">
                            Join Webiner
                          </button>
                        </div>
                      }
                    </div>
                  }
                  <div class="event-preview">
                    <img [src]="webinerDetails.thumbnail" class="event-image" />
                    <div class="event-details">
                      @if (rruledata(); as rrule) {
                        <div class="event-schedule date-range">
                          <mat-icon color="primary">{{
                            rrule.endDate ? 'date_range' : 'event'
                          }}</mat-icon>
                          {{ rrule.startDate }} {{ rrule.endDate ? '-' : '' }}
                          {{ rrule.endDate }}
                        </div>
                        <div class="event-schedule frequency-day">
                          @if (rrule.endDate && rrule.frequency) {
                            <mat-icon color="primary">view_week</mat-icon>
                            {{ rrule.frequency }} on
                            {{ rrule.daysOfWeek.join(' | ') }}
                          }
                        </div>
                        <div class="event-schedule event-time">
                          <mat-icon color="primary">schedule</mat-icon>
                          {{ webinerDetails.startTime }} to
                          {{ webinerDetails.endTime }}
                        </div>
                      }
                      <mat-chip-listbox
                        aria-label="Event Tags"
                        class="event-tags">
                        @for (tag of webinerDetails.tagToEvents; track tag) {
                          <mat-chip-option color="primary">{{
                            tag.tag.name
                          }}</mat-chip-option>
                        }
                      </mat-chip-listbox>
                      <div class="event-action">
                        <span class="price-text">
                          @if (webinerDetails.price > 0) {
                            Price: {{ '$ ' + webinerDetails.price }}
                          } @else {
                            FREE EVENT
                          }
                        </span>
                        @if (this.user()?.id !== webinerDetails.author) {
                          <button
                            mat-raised-button
                            (click)="checkout()"
                            [disabled]="
                              !!(webinerDetails?.participants?.length ?? 0 > 0)
                            "
                            class="secondary-button">
                            {{
                              (webinerDetails?.participants?.length ?? 0 > 0)
                                ? 'Already Enrolled'
                                : 'ENROLL NOW'
                            }}
                          </button>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </dashboard-content-container>
        }
      }
    </div>
  `,
  styles: [
    `
      @import 'mixins';
      .main-container {
        .inner-container {
          padding: 0 1.25rem;
        }
        .event-container {
          display: flex;
          align-items: stretch;
          width: 100%;
          gap: 1.5rem;
          margin: 4rem 0;
          .event-description {
            width: 70%;
            border-radius: 0.75rem;
            padding: 1.5rem;
            -webkit-box-shadow: 0px 0px 26px -11px rgba(0, 0, 0, 0.54);
            -moz-box-shadow: 0px 0px 26px -11px rgba(0, 0, 0, 0.54);
            box-shadow: 0px 0px 26px -11px rgba(0, 0, 0, 0.54);
          }
          .event-preview {
            width: 30%;
            border-radius: 0.75rem;
            padding: 1.5rem;
            -webkit-box-shadow: 0px 0px 26px -11px rgba(0, 0, 0, 0.54);
            -moz-box-shadow: 0px 0px 26px -11px rgba(0, 0, 0, 0.54);
            box-shadow: 0px 0px 26px -11px rgba(0, 0, 0, 0.54);
            .event-image {
              width: 100%;
              border-radius: 4px;
              object-fit: cover;
              object-position: center;
              aspect-ratio: 16/9;
            }
            .event-details {
              padding: 1.5rem 0;
              .event-schedule {
                display: flex;
                align-items: center;
                gap: 1rem;
              }
            }
            .event-action {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-top: 1.25rem;
              .price-text {
                color: #4e4e4e;
                text-align: center;
                @include font('DM sans', 20px, 700, normal, normal);
                text-transform: uppercase;
              }
            }
          }
          //           .event-preview {
          //   .event-details {
          // .price-text {
          //   color: #4e4e4e;
          //   text-align: center;
          //   @include font('DM sans', 20px, 700, normal, normal);
          //   text-transform: uppercase;
          // }
          //   }
          // }
        }
        .tabs-content {
          padding: 1.5rem 0;
          .tab-header {
            @include font('DM sans', 24px, 600, normal, normal);
          }
          .author-info {
            padding-top: 1.75rem;
            @include font('DM sans', 16px, 400, normal, normal);
          }
          .description-content {
            padding-top: 1.75rem;
            @include font('DM sans', 16px, 400, normal, normal);
          }
        }
        .event-meeting-back {
          margin-top: 1.25rem;
        }
      }
    `,
  ],
})
export class PublicWebinerDetailsComponent {
  route = inject(ActivatedRoute);
  webinerService = inject(WebinerService);
  user = inject(AuthStore).profile;
  router = inject(Router);
  mtxDialog = inject(MtxDialog);
  queryClient = injectQueryClient();
  checkoutSession = signal<boolean>(false);
  eventId = signal<string>('');
  returnUrl = signal<string[]>([]);
  isLiveWebiner = signal<boolean>(false);
  stripeKey = '';
  amount = 0;
  currency = '';
  constructor() {
    this.route.params.subscribe(params => {
      this.eventId.set(params['id']); // Access the 'id' parameter from the URL
      this.returnUrl.set(['events', this.eventId()]);
    });
  }
  webinerDetailsData = injectQuery(() => ({
    queryKey: ['market-webiner-details', this.eventId()],
    queryFn: () =>
      lastValueFrom(
        this.webinerService.fetchMarketplaceEventsById(this.eventId())
      ),
    refetchOnWindowFocus: false,
    enabled: !!this.eventId(),
  }));
  async completedPayment(event: any) {
    if (event) {
      this.checkoutSession.set(false);
      await this.queryClient.invalidateQueries({
        queryKey: ['market-webiner-details', this.eventId()],
      });
    }
  }
  checkout() {
    if (this.user()) {
      this.webinerService
        .initiatePayment({
          type: 'EVENT',
          resourceId: this.eventId(),
        })
        .subscribe(
          (res: any) => {
            if (!res.data.amount || res.data.amount === 0) {
              this.queryClient.invalidateQueries({
                queryKey: ['market-webiner-details', this.eventId],
              });
            } else {
              this.stripeKey = res.data.clientSecret;
              this.amount = res.data.amount;
              this.currency = res.data.currency;
              this.checkoutSession.set(true);
            }
          },
          err => {
            console.error(err);
            this.checkoutSession.set(false);
          }
        );
    } else {
      this.router.navigate(['auth'], {
        queryParams: {
          returnUrl: this.router.url,
        },
      });
    }
  }
  rruledata = computed(() => {
    const data = this.webinerDetailsData.data();
    if (!data?.rrule) {
      return null;
    }

    const rruleObj = rrulestr(data.rrule);
    return {
      startDate: rruleObj.origOptions.dtstart
        ? format(new Date(rruleObj.origOptions.dtstart), 'dd-MMM-yyyy')
        : null,
      endDate: rruleObj.origOptions.until
        ? format(new Date(rruleObj.origOptions.until), 'dd-MMM-yyyy')
        : null,
      frequency: rruleObj.origOptions.freq
        ? this.getFrequency(rruleObj.origOptions.freq)
        : null,
      daysOfWeek: rruleObj.origOptions.byweekday
        ? this.extractDaysFromRrule(data.rrule)
        : [],
    };
  });
  getFrequency(freq: number): string {
    switch (freq) {
      case 0:
        return 'Yearly';
      case 1:
        return 'Monthly';
      case 2:
        return 'Weekly';
      case 3:
        return 'Daily';
      case 4:
        return 'Hourly';
      case 5:
        return 'Minutely';
      case 6:
        return 'Secondly';
      default:
        return 'Unknown';
    }
  }

  extractDaysFromRrule(rruleString: string): string[] {
    // Extract the RRULE part from the string
    const rrulePart = rruleString.split('RRULE:')[1];
    if (!rrulePart) {
      return [];
    }

    // Split the RRULE string by semicolons
    const components = rrulePart.split(';');
    const bydayComponent = components.find(component =>
      component.startsWith('BYDAY')
    );

    let days: string[] = [];
    if (bydayComponent) {
      days = bydayComponent.split('=')[1].split(',');
    }

    // Map of RRULE days to full names
    const dayMap: { [key: string]: string } = {
      MO: 'Monday',
      TU: 'Tuesday',
      WE: 'Wednesday',
      TH: 'Thursday',
      FR: 'Friday',
      SA: 'Saturday',
      SU: 'Sunday',
    };

    return days.map(day => dayMap[day]);
  }
  private addTimeStringToDate(date: Date, timeStr: string): Date {
    const timeReg = /(\d+)[.|:](\d+)\s?(\w+)/;
    const parts = timeStr.match(timeReg);
    if (!parts) return date;
    const hours = /am/i.test(parts[3])
      ? (function (am) {
          return am < 12 ? am : 0;
        })(parseInt(parts[1], 10))
      : (function (pm) {
          return pm < 12 ? pm + 12 : 12;
        })(parseInt(parts[1], 10));

    const minutes = parseInt(parts[2], 10);

    date.setHours(hours);

    date.setMinutes(minutes);
    date.setSeconds(0);

    return date;
  }
  joinLiveWebiner() {
    const webinerDetails = this.webinerDetailsData.data();
    if (!webinerDetails) return;
    const rrule = RRule.fromString(webinerDetails.rrule);
    const webinerStartTime = rrule.origOptions.dtstart
      ? this.addTimeStringToDate(
          new Date(rrule.origOptions.dtstart),
          webinerDetails.startTime
        )
      : this.addTimeStringToDate(new Date(), webinerDetails.startTime);

    const webinerEndTime = rrule.options?.until
      ? this.addTimeStringToDate(
          new Date(rrule.options.until),
          webinerDetails.endTime
        )
      : this.addTimeStringToDate(new Date(), webinerDetails.endTime);
    // if (isBefore(new Date(), webinerStartTime)) {
    //   return this.mtxDialog.alert(
    //     'Event Not Started',
    //     'Event has not started yet. Please check back later.'
    //   );
    // }

    // if (isAfter(new Date(), webinerEndTime)) {
    //   return this.mtxDialog.alert(
    //     'Event Ended',
    //     'Event has already ended. Please check out other events.'
    //   );
    // }
    this.isLiveWebiner.set(!this.isLiveWebiner());
  }
  getMeetingCode = computed(() => {
    const participants = this.webinerDetailsData.data()?.participants || [];
    if (participants.length > 0) {
      switch (participants[0].role) {
        case 'HOST':
          return this.webinerDetailsData.data()!.meetingHostCode;
        case 'CO_HOST':
          return this.webinerDetailsData.data()!.meetingCoHostCode;
        case 'GUEST':
          return this.webinerDetailsData.data()!.meetingGuestCode;
        default:
          return this.webinerDetailsData.data()!.meetingGuestCode;
      }
    } else {
      return this.webinerDetailsData.data()!.meetingHostCode;
    }
  });
}
