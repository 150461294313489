<div class="event-participant-container">
  <div class="event-participant-header">
    <h6 class="event-participant-title mat-title-medium">Event Participants</h6>

    <button mat-icon-button (click)="onClose()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="event-participant-action">
    @if (isCreator()) {
      <button mat-raised-button color="primary" (click)="openAddParticipant()">
        Add Participants
      </button>
    }
  </div>
  <div class="event-participant-body">
    @if (participantsData.isPending()) {
      loading...
    } @else if (participantsData.isError()) {
    } @else {
      @if (cohostData(); as cohosts) {
        <div class="table-header mat-title-medium">Event Co-hosts</div>
        <table class="participant-container">
          <tr>
            <th>Name</th>
            <th>Status</th>
          </tr>
          @for (cohost of cohosts; track cohost) {
            <tr>
              <td>
                @if (cohost.user.firstname) {
                  {{ cohost.user.firstname }}
                  {{ cohost.user.lastname }}
                } @else {
                  {{ cohost.user.email }}
                }
              </td>
              <td>
                @if (isCreator()) {
                  <div class="form-input-container">
                    <mat-slide-toggle
                      [(ngModel)]="cohost.isActive"
                      (change)="updateStatus($event)"
                      labelPosition="after"></mat-slide-toggle>
                  </div>
                } @else {
                  {{ cohost.isActive ? 'Active' : 'Inactive' }}
                }
              </td>
            </tr>
          }
        </table>
      }
      @if (guestData(); as guests) {
        <div class="table-header mat-title-medium">Event Guests</div>
        <table class="participant-container">
          <tr>
            <th>Name</th>
            <th>Status</th>
          </tr>
          @for (guest of guests; track guest) {
            <tr>
              <td>
                @if (guest.user.firstname) {
                  {{ guest.user.firstname }}
                  {{ guest.user.lastname }}
                } @else {
                  {{ guest.user.email }}
                }
              </td>
              <td>
                @if (isCreator()) {
                  <div class="form-input-container">
                    <mat-slide-toggle
                      [(ngModel)]="guest.isActive"
                      (change)="updateStatus($event)"
                      labelPosition="after"></mat-slide-toggle>
                  </div>
                } @else {
                  {{ guest.isActive ? 'Active' : 'Inactive' }}
                }
              </td>
            </tr>
          }
        </table>
      }
    }
  </div>
</div>
