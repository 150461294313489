<dashboard-content-container title="Courses">
  <ng-container content-action></ng-container>
  <ng-container content-inner>
    <div class="main-container">
      @if (webinerQueries.isPending()) {
        <div>loading...</div>
      } @else if (webinerQueries.isError()) {
      } @else {
        @if (webinerQueries.data(); as webiners) {
          @for (webiner of webiners; track webiner) {
            <trlp-webiner-card [WebinerDetails]="webiner"></trlp-webiner-card>
          } @empty {
            <div class="empty-container">
              <mat-icon>info</mat-icon>
              <div class="mat-body-small">
                You haven't purchased any events yet.
              </div>
            </div>
          }
        }
      }
    </div>
  </ng-container>
</dashboard-content-container>
