import { CommonModule } from '@angular/common';
import { Component, inject, input } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';
import { NgSelectModule } from '@ng-select/ng-select';
import { injectQuery } from '@tanstack/angular-query-experimental';
import { WebinerCardComponent } from '@trueleap/ui-kit';
import { lastValueFrom } from 'rxjs';
import { WebinerService } from '../webiner.service';

@Component({
  selector: 'trueleap-plus-features-webiner',
  standalone: true,
  imports: [CommonModule, WebinerCardComponent, MatChipsModule, NgSelectModule],
  templateUrl: './features-webiner.component.html',
  styleUrl: './features-webiner.component.scss',
})
export class FeaturesWebinerComponent {
  isCreator = input<boolean>(false);
  webinerService = inject(WebinerService);
  webinerQueries = injectQuery(() => ({
    queryKey: ['events'],
    queryFn: () => lastValueFrom(this.webinerService.fetchMarketplaceEvents()),
  }));
}
