<h2 mat-dialog-title>Add Participant</h2>
<mat-dialog-content>
  <form [formGroup]="participantForm" class="form-container">
    <div class="form-input-container">
      <div class="form-label">User</div>
      <ng-select formControlName="userId">
        <ng-option
          *ngFor="let user of usersFiltered() as users"
          [value]="user.id"
          >{{ user.firstname ? user.firstname : user.email }}</ng-option
        >
      </ng-select>
    </div>
    <div class="form-input-container">
      <div class="form-label">Role</div>
      <ng-select formControlName="role" [appendTo]="'body'">
        <ng-option *ngFor="let role of ROLES as roles" [value]="role.value">{{
          role.name
        }}</ng-option>
      </ng-select>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button (click)="cancel()">Cancel</button>
  <button mat-button (click)="save()">Add</button>
</mat-dialog-actions>
