<dashboard-content-container header="Events">
  <ng-container content-action>
    <div class="admin-access-menu">
      <div class="button-container">
        <button
          mat-raised-button
          color="primary"
          class="primary-button"
          [routerLink]="['./../', 'create']">
          <mat-icon>add</mat-icon>
          Add Event
        </button>
        @if (dataView() !== 'CALENDAR') {
          <button mat-raised-button (click)="openDialog()">
            <mat-icon>filter_alt</mat-icon>Filter
          </button>
        }
        @if (dataView() !== 'CALENDAR') {
          <ng-select
            [multiple]="false"
            class="ng-select-custom"
            [ngModel]="this.sortSignal()"
            [clearable]="false"
            (ngModelChange)="onSortChange($event)">
            <ng-option
              *ngFor="let sort of this.sortOption as tags"
              [value]="sort.value"
              >{{ sort.viewValue }}</ng-option
            >
          </ng-select>
        }
        <mat-icon
          class="view-button"
          [ngClass]="{ active: dataView() === 'GRID' }"
          (click)="changeDataView('GRID')"
          >grid_view</mat-icon
        >
        <mat-icon
          class="view-button"
          [ngClass]="{ active: dataView() === 'LIST' }"
          (click)="changeDataView('LIST')"
          >format_list_bulleted</mat-icon
        >
        <mat-icon
          class="view-button"
          [ngClass]="{ active: dataView() === 'CALENDAR' }"
          (click)="changeDataView('CALENDAR')"
          >calendar_month</mat-icon
        >
      </div>
    </div>
  </ng-container>

  <ng-container content-inner>
    @if (dataView() === 'CALENDAR') {
      <div class="calendar-container">
        <full-calendar
          [options]="calendarOptions"
          [events]="eventCalendarData.data() || []"></full-calendar>
      </div>
    } @else {
      <div
        class="content-container"
        [ngClass]="
          dataView() === 'LIST'
            ? 'content-container-list'
            : 'content-container-grid'
        ">
        @if (webinerQueries.isPending()) {
          <div>loading...</div>
        } @else if (webinerQueries.isError()) {
        } @else {
          @if (webinerQueries.data(); as webiners) {
            @for (webiner of webiners; track webiner) {
              <trlp-webiner-card
                [viewMode]="dataView() === 'LIST' ? 'LIST' : 'GRID'"
                [WebinerDetails]="webiner"></trlp-webiner-card>
            }
          }
        }
      </div>
    }
  </ng-container>
</dashboard-content-container>
