import { Route } from '@angular/router';
import { PublicWebinerComponent } from './public-webiners.component';
import { CreateWebinerComponent } from './create-webiner/create-webiner.component';
import { WebinersComponent } from './webiners/webiners.component';
import { EventRecordings } from './event-recordings';
import { EventRecordingDetails } from './event-recording-details';
import { FeatureWebinerDetailsComponent } from './feature-webiner-details/feature-webiner-details.component';

export const featuresWebinerRoutes: Route[] = [
  {
    path: '',
    component: WebinersComponent,
    data: {
      breadcrumb: {
        label: 'Events',
      },
    },
  },
  {
    path: 'create',
    component: CreateWebinerComponent,
    data: {
      breadcrumb: {
        label: 'Create Event',
      },
    },
  },
  {
    path: ':id/edit',
    component: CreateWebinerComponent,
    data: {
      breadcrumb: {
        label: 'Edit Event',
      },
    },
  },
  {
    path: 'list',
    component: WebinersComponent,
    data: {
      breadcrumb: {
        label: 'All Events',
      },
    },
  },
  {
    path: 'recordings',
    component: EventRecordings,
    data: {
      breadcrumb: {
        label: 'Recordings',
      },
    },
  },
  {
    path: 'recordings/:eventId/recording/:recordingId',
    component: EventRecordingDetails,
  },
  {
    path: ':id',
    component: FeatureWebinerDetailsComponent,
    data: {
      breadcrumb: {
        label: 'Event Details',
      },
    },
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'list',
  },
];
