<mat-card class="filter-card">
  <mat-card-header class="section-header">Filter</mat-card-header>
  <mat-divider></mat-divider>
  <mat-accordion [formGroup]="filterForm" displayMode="flat" [multi]="true">
    <mat-expansion-panel expanded="true">
      <mat-expansion-panel-header>
        <mat-panel-title> Date Range </mat-panel-title>
      </mat-expansion-panel-header>
      <section class="example-section">
        <mat-form-field>
          <mat-label>Enter a date range</mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input
              matStartDate
              formControlName="startDate"
              placeholder="Start date" />
            <input
              matEndDate
              formControlName="endDate"
              placeholder="End date" />
          </mat-date-range-input>
          <mat-datepicker-toggle
            matIconSuffix
            [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>

          @if (
            filterForm.controls['startDate'].hasError('matStartDateInvalid')
          ) {
            <mat-error>Invalid start date</mat-error>
          }
          @if (filterForm.controls['endDate'].hasError('matEndDateInvalid')) {
            <mat-error>Invalid end date</mat-error>
          }
        </mat-form-field>
      </section>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title> Host </mat-panel-title>
      </mat-expansion-panel-header>
      <section class="example-section">
        <ng-select
          formControlName="author"
          appendTo="body"
          [multiple]="true"
          [loading]="eventAuthorData.isPending()"
          [notFoundText]="eventAuthorData.isError() ? 'No Authors' : ''"
          class="ng-select-custom">
          <ng-option
            *ngFor="let author of eventAuthorData.data() as authors"
            [value]="author.id"
            >{{ author.firstname }}</ng-option
          >
        </ng-select>
      </section>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title> Category </mat-panel-title>
      </mat-expansion-panel-header>
      <section class="example-section">
        <ng-select
          formControlName="categories"
          appendTo="body"
          [multiple]="true"
          [loading]="eventTagsData.isPending()"
          [notFoundText]="eventTagsData.isError() ? 'No Authors' : ''"
          class="ng-select-custom">
          <ng-option
            *ngFor="let tag of eventTagsData.data() as tags"
            [value]="tag.id"
            >{{ tag.name }}</ng-option
          >
        </ng-select>
      </section>
    </mat-expansion-panel>
  </mat-accordion>
  <div class="action-button">
    <button mat-raised-button color="primary" (click)="filter()">Apply</button>
  </div>
</mat-card>
